import React, { useState, useEffect } from "react";
import axios from "axios";
import Movie2 from "./Movie2";
import queryString from 'query-string'

import { useHistory,useLocation } from 'react-router-dom';
import Spinner from "./Spinner"

const CategoriesMovies = (props) => {

    const { idGenre } = props;
    const history = useHistory();  
    const  {search}  = useLocation();
    const category = queryString.parse(search)
    
    const [data, setData] = useState([]);
    const [id, setId] = useState();
    const [categories, setCategories] = useState([]);
    const idParam = idGenre ? idGenre : id;
    
    const [page,setPage] = useState(1);
    let URL ='' 
    if(idParam){
        URL = `https://api.themoviedb.org/3/discover/movie?api_key=a67b57849deb687f2cd49d7a8298b366&language=fr-FR&page=${page}&with_genres=${idParam}`;
    }else{
        URL = `https://api.themoviedb.org/3/discover/movie?api_key=a67b57849deb687f2cd49d7a8298b366&language=fr-FR&page=${page}&with_genres=${category?.q}`;
    }
    const URL_GENRES = "https://api.themoviedb.org/3/genre/movie/list?api_key=a67b57849deb687f2cd49d7a8298b366&language=fr-FR";
    const [isLoading,setIsLoading] = useState(true)
    
    useEffect(() => {
        if(isLoading){
            axios.get(URL).then((res) => {
                setData(res.data.results);
            });
            axios.get(URL_GENRES).then((res) => {
              setCategories(res.data.genres);
          });
          setTimeout(()=> {
            setIsLoading(false)
          },1000)
        }
       
    }, [idParam,isLoading,page,id]);
    
    const onChange = (e) => {
        setId(e.target.value);
        history.push(`/categories/${e.target.value}`)
        setIsLoading(true)
    }
    const PageNumbersArray = [...Array(page <=  4 ? 6 : page+2).keys()].map((_,index)=>index).filter(index=>index > 0 && index  >= (page - 4) )
    console.log(PageNumbersArray)
    console.log(page)
    return(
        <div className="categoriesMovies">
            <img className="categories_img" src="categories.jpg" alt="categories" />

          
            <div className="sort-container">
            <select value={id} onChange={onChange} >
              <option className="seletCategories" >Sélectionnez une catégorie</option>
              {categories.map((genre) => {
                return <option value={genre.id}>{genre.name}</option>
              })}
            </select>
        </div>
          
            {idParam | category?.q ? (
                <div>
                <div style={{display:"flex",flexDirection:"row",justifyContent:"center",color:"red"}}>
                    <button className="page-next" onClick={()=>{setPage(page-1);setIsLoading(true)}}>Precédent</button>
                    <div style={{display:"flex", gap:"10px"}}>
                        {PageNumbersArray.map(num=>
                        <span className="page-button" onClick={()=>{setPage(num);setIsLoading(true)}} style={{color:num==page?'dimgrey':''}} >
                            {num}
                        </span>)}
                    </div>
                    <button className="page-next" onClick={()=>{setPage(page+1);setIsLoading(true)}}>Suivant</button>
                </div>
                <ul className="categoriesMovies-list">
                    {isLoading ? <Spinner isLoading={isLoading}/> : 
                    <>
                    {data.map((movie) => (
                        <Movie2 movie={movie} key={movie.id} onClickMovie={() => history.push(`/movieDetails/${movie.id}`)}/>
                    ))}
                    </>
                    }
                </ul>
                </div>
            ) : (
                <div className="placeholder-container">
                  {/*  <h4 className="NotCat">Rien à afficher, veuillez sélectionner une catégorie</h4>*/}
                </div>
            )}    
        </div>
    )
}

export default CategoriesMovies;