import React from "react";


const Banner = () => {
    return(
        
        <div className="banner__box">
              <img className="banner__img" src="./bannerAccueil.jpg" alt="logo du site" />
      
        </div>
    )
}

export default Banner;