import React from "react";

const Logo = () => {
    return(
        <div className="logo">
            <img src="../images/Logo_devflix.jpg" alt="logo" />
           
        </div>
    )
}

export default Logo;