import React , { useState, useEffect } from 'react';
import axios from 'axios';
import Movie from './Movie';
import { useHistory, Link } from 'react-router-dom';


const SimilarMovies = ({movie_id}) => {
    //Backend
    const [similar, setSimilar] = useState([]);
    const history = useHistory();

    useEffect(() => {
        axios.get(`https://api.themoviedb.org/3/movie/${movie_id}/similar?api_key=6625ff3fdd9fb20f78f226927fb449e9&language=fr-FR&page=1`)
        .then((res) => {console.log(res);setSimilar(res.data.results)});
    }, []);

    //Front
    return(
        <div className='popularMovies'>
            <div className="heading2">
                <div>
                    <h1 className='titleCategories'>Films que vous pouriez aimer</h1>
                </div>


{/* À VOIR SI UTILISER LE VOIR TOUS POUR AMENER VERS PAGE AVEC TOUS LES FILMS */}
                
                
                
                <div className="tous">
                    <h2>Voir Tous</h2>
                    <Link to="/categories"><button className="next">❯</button></Link>               
                </div>
            </div>
            {<ul className='popularMovies-list'>
                {similar.slice(0,5).map((m) => (
                    <div onClick={()=>{history.push(`/`);setTimeout(()=> {history.push(`/movieDetails/${m.id}`)},10)}}>
                        <Movie movie={m} key={m.id} />
                    </div>
                ))}
            </ul>}
        </div>
        
    )
}

export default SimilarMovies;