import React from 'react';
import Logo from './Logo';

const Footer = () => {
    return(

        <footer>
          <span class="logo_name"><img class="logo_image" src="../images/Logo_devflix.jpg" alt="logo" /></span>
            <div class="container">
              <ul class="foote_bottom_ul_amrc">
                <li><a href="/">Accueil</a></li>
                <li><a href="/categories">Catégories</a></li>
                <li><a href="/search">Recherche</a></li>
                <li><a href="/a-propos">À propos</a></li>
              </ul>
              <p class="text-center">Devflix © 2022 Tous droits réservés.</p>
                <div class="social-links social-1 col-6">
                        <a href=""><i class="fa fa-facebook-f"></i></a>
                        <a href=""><i class="fa fa-twitter"></i></a>
                        <a href=""><i class="fa fa-linkedin-in"></i></a>
                        <a href=""><i class="fa fa-instagram"></i></a>
                        <a href=""><i class="fa fa-tumblr"></i></a>
                        <a href=""><i class="fa fa-reddit-alien"></i></a>
                </div>
            </div>
        </footer>
    )
}

export default Footer;