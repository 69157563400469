import React , { useState, useEffect } from 'react';
import axios from 'axios';
import Movie from './Movie';
import { useHistory, Link } from 'react-router-dom';

const PopularMovies = () => {
    //Backend
    const [popular, setPopular] = useState([]);
    const history = useHistory();

    useEffect(() => {
        axios.get("https://api.themoviedb.org/3/movie/popular?api_key=a67b57849deb687f2cd49d7a8298b366&language=fr-FR&page=1").then((res) => setPopular(res.data.results));
    }, []);

    //Front
    return(
        <div className='popularMovies'>
            <div className="heading2">
                <div>
                    <h1 className='titleCategories'>Films Populaires</h1>
                </div>
                <div className="tous">
                    <h2>Voir Tous</h2>
                    <Link to="/categories"> <button className="next">❯</button></Link>               
                </div>
            </div>
            {<ul className='popularMovies-list'>
                    {popular.slice(0,5).map((m) => (
                        <Movie movie={m} key={m.id} onClickMovie={() => history.push(`/movieDetails/${m.id}`)} />
                    ))}
                </ul>}
        </div>
    )
}



export default PopularMovies;