import React , { useState, useEffect } from 'react';
import axios from 'axios';
import Movie from './Movie';
import { useHistory, Link } from 'react-router-dom';


const TopMovies = () => {
    //Backend
    const [top, setTop] = useState([]);
    const history = useHistory();

    useEffect(() => {
        axios.get("https://api.themoviedb.org/3/movie/top_rated?api_key=6625ff3fdd9fb20f78f226927fb449e9&language=fr-FR&page=1").then((res) => setTop(res.data.results));
    }, []);

    //Front
    return(
        <div className='popularMovies'>
            <div className="heading2">
                <div>
                    <h1 className='titleCategories'>Top Films</h1>
                </div>
                <div className="tous">
                    <h2>Voir Tous</h2>
                    <Link to="/categories"><button className="next">❯</button></Link>               
                </div>
            </div>
            {<ul className='popularMovies-list'>
                {top.slice(0,5).map((m) => (
                    <Movie movie={m} key={m.id} onClickMovie={() => history.push(`/movieDetails/${m.id}`)} />
                ))}
            </ul>}

        </div>
    )
}



export default TopMovies;