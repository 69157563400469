import React, { useState, useEffect ,CSSProperties} from "react";
import axios from "axios";
import Footer from "../components/Footer";
import Actor from "../components/Actor";
import { useHistory, useParams } from "react-router-dom";
import { Button } from "bootstrap";
import bootstrap from "bootstrap";
import { Container } from "react-bootstrap";
import Navigation from "../components/Navigation";
import PacmanLoader from "react-spinners/PacmanLoader";
import SimilarTv from "../components/SimilarTv";
import BackButton from "../components/BackButton";
import StarIcon from "@mui/icons-material/Star";
import ModalVideo from 'react-modal-video';


const TvDetail = (props) => {
    const { id } = useParams()

   
    const URL = `https://api.themoviedb.org/3/tv/${id}?api_key=a67b57849deb687f2cd49d7a8298b366&language=fr-FR`;
    const URL_CREDITS = `https://api.themoviedb.org/3/tv/${id}/credits?api_key=a67b57849deb687f2cd49d7a8298b366&language=en-US`;
    const URL_SIMILAR = `https://api.themoviedb.org/3/tv/${id}/similar?api_key=a67b57849deb687f2cd49d7a8298b366&language=en-US`;
    const URL_VIDEO = `https://api.themoviedb.org/3/tv/${id}/videos?api_key=6625ff3fdd9fb20f78f226927fb449e9&language=en-US`;
    const [detail, setDetail] = useState({});
    const [genres, setGenres] = useState([]);
    const [credits, setCredits] = useState([]);
    const [similar, setSimilar] = useState([]);
    const [video, setVideo] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const history = useHistory();
    const [isOpen, setOpen] = useState(false);


    useEffect(() => {
        if (isLoading) {
            axios.get(URL).then((res) => {
                setDetail(res.data);
                setGenres(res.data.genres);
            });
            axios.get(URL_CREDITS).then((res) => {
                console.log(res.data)
                setCredits(res.data.cast);
            });
            axios.get(URL_SIMILAR).then((res) => {
                setSimilar(res.data.similar);
            });
            axios.get(URL_VIDEO).then((res) => {
                setVideo(res.data.results[0]);

            });
            setTimeout(() => {
                setIsLoading(false)
            }, 3000)
        }

    }, [URL, URL_CREDITS, URL_SIMILAR, URL_VIDEO]);
   
    return (
        <React.Fragment>
            <Navigation />
            <div
                className="Backdrop"
                 /*style={{
                backgroundSize: "cover",
                backgroundImage: `url(https://image.tmdb.org/t/p/original${detail.backdrop_path})`,
                backgroundPosition: "center center",
                backgroundAttachment: "fixed",
                maxWidth: "100%",
                maxHeight: "100%",
            }}*/
            >
                {isLoading ? <Spinner className="spinner" isLoading={isLoading}/> : 
                <div className="details-container">
                  <div className="movie_card" id="bright">
                        <div className="info_section">
                            <div className="movie_header">
                            <BackButton className = "retourActor mb-3 btn-primary" />
                                <img
                                    className="locandina"
                                    src={`https://image.tmdb.org/t/p/original${detail.backdrop_path}`}
                                    alt="poster"
                                />
                            </div>    
                            <div className="movie_infos">
                                <h1 className="movie_titre">{detail.title}</h1>
                                <span className="movie__star">
                                    <StarIcon />
                                    {detail.vote_average} / 10
                                </span>
                            </div>    
                            <div className="movie_infos2">
                                <h4 className="movie_date">{detail.release_date}  </h4>
                                <div className="movie_desc">
                                    <h2>Synopsis : </h2>
                                    <p className="text">{detail.overview}</p>
                                </div>
                                <div className="movie_categories">
                                    <h2>Type : </h2>
                                    {genres.map((g) => {
                                        return (
                                            <p
                                             key={g.id}
                                            className="type"
                                            onClick={() => history.push("/categories", { id: g.id })}>
                                            {g.name}</p>
                                            );
                                        })}
                                </div>
                            </div>

                            <div className="movie_acteurs">
                                    <h2 className="textActor">Acteurs:</h2>
                                    <ul className="actors_desc">

                                        {credits
                                            .slice(0, 6)
                                            .map(credit => {
                                                return (
                                                    <Actor
                                                        actor={credit}
                                                        key={credit.id}
                                                        onClickActor={() => history.push("/actorDetails", { id: credit.id })} />
                                                );
                                            })
                                        }

                                    </ul>
                                </div>
                       {/*  ÇA FONCTIONNE PAS =(  <div style={{ position: 'relative' }} >*/}
                             {/*<div onClick={() => setOpen(true)} style={{ position: 'absolute', zIndex: 5, width: '100%', height: "315px", background: 'red', left: 0, top: 0, opacity: '0' }}></div>*/}

                             <div style={{ position: 'relative' }} >
                                        <div onClick={() => setOpen(true)} style={{ position: 'absolute', zIndex: 5, width: '100%', height: "315px", background: 'red', left: 0, top: 0, opacity: '0' }}></div>

                                        <Container className="video mt-5 w" >
                                            <iframe width="560" height="315" src={`https://www.youtube.com/embed/${video?.key}`} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                        </Container>

                                    </div>
                                </div>
                                <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId={video?.key} onClose={() => setOpen(false)} />
                           
                           {/* <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId={video.key} onClose={() => setOpen(false)} />*/}
                        
                    </div>
                    <SimilarTv tv_id={id}/>
                </div>
                 }
                <div className="footer-container">
                    <Footer />
                </div>
            </div>
        </React.Fragment>
    );
};

export default TvDetail;

const override = {
    display: "block",
    margin: "112px auto",
    borderColor: "red",
 };
const Spinner = ({isLoading})=>{
    return (
        <PacmanLoader
        color="red"
        loading={isLoading}
        cssOverride={override}
        size={150}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    )
}
