import React from 'react';
import { NavLink } from 'react-router-dom';
import { Navbar, Nav } from 'react-bootstrap';
import Logo from "../components/Logo";
import Button from 'react-bootstrap/Button';


const Navigation = () => {
    return(
        <Navbar expand="lg" className='={show ? "navbar nav__show" : "navbar"}'>
          
                  <Navbar.Brand ><Logo /></Navbar.Brand>
                  <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                  <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="mx-auto my-2 my-lg-0 navigation" navbarScroll
        >
                        <NavLink exact to="/" activeClassName='nav-active'>
                                ACCUEIL
                        </NavLink>
                        <NavLink exact to="/categories" activeClassName='nav-active'>
                                CATÉGORIES
                        </NavLink>
                        <NavLink exact to="/search" activeClassName='nav-active'>
                                RECHERCHE
                        </NavLink>
                        <NavLink exact to="/a-propos" activeClassName='nav-active'>
                                À PROPOS
                        </NavLink>
                    </Nav>
                        <Button className='rejoindre'>Rejoindre Devflix</Button>
                  </Navbar.Collapse>
              </Navbar>
)
}

export default Navigation;