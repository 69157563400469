import PacmanLoader from "react-spinners/PacmanLoader";



const override = {
    display: "block",
    margin: "112px auto",
    borderColor: "red",
 };

const Spinner = ({isLoading})=>{
    return (
        <PacmanLoader
        color="red"
        loading={isLoading}
        cssOverride={override}
        size={150}
        aria-label="Loading Spinner"
        data-testid="loader"
        
      />
    )
}
export default Spinner;