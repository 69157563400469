import React, { useState, useEffect } from "react";
import axios from "axios";
import Logo from "../components/Logo";
import Navigation from "../components/Navigation";
import Footer from "../components/Footer";
import BackButton from "../components/BackButton";
import { Button } from "react-bootstrap";
import Banner2 from "../components/Banner2";
import ActorMovies from "../components/ActorMovies"

const ActorDetails = (props) => {

    const id = props.location.state.id;
    const [detail, setDetail] = useState({});
    const URL = `https://api.themoviedb.org/3/person/${id}?api_key=a67b57849deb687f2cd49d7a8298b366&language=en-US`
    const [loading,setLoading] = useState(true)
    useEffect(() => {
        if(loading){
            axios.get(URL).then((res) => {
                setDetail(res.data);
                setLoading(false)
            });
        }
    }, [id]);

    return (
        <React.Fragment>
            <Navigation />
            <div className="acteurContainer">
                <div className="acteur_card" id="bright">
                <BackButton className = "retourActor ml-150 btn-primary" />
                    <div className="info_section_acteur">
                        <div className="acteur_header">
                            <img 
                                className="actor_image"
                                src={
                                    detail.profile_path ?
                                        `https://image.tmdb.org/t/p/original${detail.profile_path}` :
                                        "https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png"
                                }
                                alt="poster"
                            />
                        </div>  
                        <div className="acteur_desc">
                            <h1 className="acteur_name">{detail.name}</h1>
                            <h4 className="acteur_birth">{detail.birthday}</h4>
                            <h2>Biographie : </h2>
                          <p className="acteur_biographie">{detail.biography}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="categoriesMovies">
            <img className="categories_img" src="./search.jpg" alt="categories" />
            </div>
            {loading? 'loading...' :  <ActorMovies actor={detail.name} />}
           

            <div className="footer-container">
                <Footer />
            </div>
        </React.Fragment>
    );
};

export default ActorDetails;