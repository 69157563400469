import React , { useState, useEffect } from 'react';
import axios from 'axios';
import Movie from './Movie';
import { useHistory, Link } from 'react-router-dom';


const ActorMovies = ({actor}) => {
    //Backend
    const [movies, setMovies] = useState([]);
    const history = useHistory();
    console.log(actor)
    useEffect(() => {
        axios.get(`https://api.themoviedb.org/3/search/person?api_key=6625ff3fdd9fb20f78f226927fb449e9&query=${actor}`).then((res) => {console.log(res.data);setMovies(res.data.results[0].known_for)});
    }, []);

    //Front
    return(
        <div className='popularMovies' >
            <div className="heading2">
                <div>
                    <h1 className='titleCategories'>Séries Télé que vous pourriez aimer</h1>
                </div>
                <div className="tous">
                    <h2>Voir Tous</h2>
                    <Link to="/categories"><button className="next">❯</button></Link>               
                </div>
            </div>
            {<ul className='popularMovies-list' style={{display:'flex',justifyContent:'center'}}>
                {movies.slice(0,5).map((m) => (
                    <Movie movie={m} key={m.id} onClickMovie={() => history.push(`/movieDetails/${m.id}`)} />
                ))}
            </ul>}

        </div>
    )
}



export default ActorMovies;