import React from "react";
import Navigation from "../components/Navigation";
import Footer from "../components/Footer";
import PopularMovies from "../components/PopularMovies";
import TopMovies from "../components/TopMovies";
import NowPlayingMovies from "../components/NowPlayingMovie";
import PopularTv from "../components/PopularTv";
import Banner from "../components/Banner";
import Carousel_ from "../components/Carousel_";


const Popular = () => {
    return (
        <div className="popular">
            <Navigation />
            <Carousel_ />
            <NowPlayingMovies />
            <TopMovies />
            <Banner />
            <PopularMovies />
            <PopularTv />
            <Footer />
        </div>
    )
}

export default Popular;