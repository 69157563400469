import React, { useState, useEffect} from 'react';
import axios from 'axios';
import Movie3 from './Movie3';
import { useHistory } from 'react-router-dom';


const SearchMovie = () => {
    const history = useHistory();
    const [data, setData] = useState([]);
    const [query, setQuery] = useState("");
    const URL = `https://api.themoviedb.org/3/search/movie?api_key=a67b57849deb687f2cd49d7a8298b366&language=fr-FR&query=${query}`;

    useEffect(() => {
        axios.get(URL).then((res) => {
            setData(res.data.results)
        });
    }, [query]);

    const onSearch = (event) => {
        setQuery(event.target.value);
    }

    return (
        <div className="categoriesMovies">
            <img className="categories_img" src="./search.jpg" alt="categories" />

        <div className='searchMovie'>
            <div className='input-container'>
                <input
                   className='input'
                   placeholder='Votre recherche'
                   onChange={onSearch}
                   
                ></input>
            
            {query ? (
                <ul className='searchMovie-list'>
                        {data.map((movie) => (
                            <Movie3 movie={movie} key={movie.id} onClickMovie={() => history.push(`/movieDetails/${movie.id}`)} />
                        ))}
                </ul>
                     ) : (
                        <div className='placeholder-container'>
                            <h4>Rien à afficher</h4>
                        </div>
                    )}  
            </div>
        </div>
        </div>
    )
}




export default SearchMovie;