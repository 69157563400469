import React from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import StarIcon from "@mui/icons-material/Star";


const truncate = (string, num) => {
    return string?.length > num ? string.substr(0, num - 3) + "..." : string;
  };
  
const Movie = (props) => {
    const { movie, onClickMovie } = props;

  return (
    <div className='movie' onClick={onClickMovie}>
    <Card style={{ width: '19rem' }} >
      <Card.Img className='movie_img' src={
                movie.poster_path ?
                `https://image.tmdb.org/t/p/original${movie.poster_path}` : 
                "../images/img_not_found_JPEG.jpg"
                } 
               alt="poster" />
      <Card.Body>
        <div className='classification'>
          <span className="mx-6 mt-2 movie__star">
              <StarIcon />
              {movie.vote_average} / 10
          </span>          
          <h5 className='data'>{movie.release_date?.split("-")[0] || movie.first_air_date?.split("-")[0]}</h5>
        </div>
      <Card.Title className='title'>
        {truncate(movie.title || movie.name)}
      </Card.Title>
    </Card.Body>
  </Card>
    </div>
  );
}

export default Movie;