import React , { useState, useEffect } from 'react';
import axios from 'axios';
import Movie from './Movie';
import { useHistory, Link } from 'react-router-dom';


const NowplayingMovies = () => {
    //Backend
    const [playing, setPlaying] = useState([]);
    const history = useHistory();

    useEffect(() => {
        axios.get("https://api.themoviedb.org/3/movie/now_playing?api_key=6625ff3fdd9fb20f78f226927fb449e9&language=fr-FR&page=1").then((res) => setPlaying(res.data.results));
    }, []);

    //Front
    return(
        <div className='popularMovies'>
            <div className="heading2">
                <div>
                    <h1 className='titleCategories'>Lancement</h1>
                </div>


{/* À VOIR SI UTILISER LE VOIR TOUS POUR AMENER VERS PAGE AVEC TOUS LES FILMS */}
                
                
                
                <div className="tous">
                    <h2>Voir Tous</h2>
                    <Link to="/categories"><button className="next">❯</button></Link>               
                </div>
            </div>
            {<ul className='popularMovies-list'>
                {playing.slice(0,5).map((m) => (
                    <Movie movie={m} key={m.id} onClickMovie={() => history.push(`/movieDetails/${m.id}`)} />
                ))}
            </ul>}
        </div>
        
    )
}

export default NowplayingMovies;