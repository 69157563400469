import React, { useEffect, useState } from "react";
import Navigation from "../components/Navigation";
import Footer from "../components/Footer";
import CategoriesMovies from "../components/CategoriesMovies";
import { useLocation, useParams } from "react-router-dom";
const Categories = (props) => {

    const { id } = useParams();
    console.log("id categories ",id)

    return(
        <div className="categories">
            <Navigation />
            <CategoriesMovies idGenre={id} />
            <Footer />
        </div>
    )
}

export default Categories;