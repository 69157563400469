import React , { useState, useEffect } from 'react';
import axios from 'axios';
import Movie from './Movie';
import { useHistory, Link } from 'react-router-dom';


const PopularTv = () => {
    //Backend
    const [poptv, setpopTv] = useState([]);
    const history = useHistory();

    useEffect(() => {
        axios.get("https://api.themoviedb.org/3/tv/popular?api_key=6625ff3fdd9fb20f78f226927fb449e9&language=en-US&page=1").then((res) => {console.log(res.data);setpopTv(res.data.results)});
    }, []);

    //Front
    return(
        <div className='popularMovies'>
            <div className="heading2">
                <div>
                    <h1 className='titleCategories'>Télé Populaire</h1>
                </div>
                <div className="tous">
                    <h2>Voir Tous</h2>
                    <Link to="/categories"><button className="next">❯</button></Link>               
                </div>
            </div>
            {<ul className='popularMovies-list'>
                {poptv.slice(0,5).map((m) => (
                    <Movie movie={m} key={m.id} onClickMovie={() => history.push(`/TvDetails/${m.id}`)} />
                ))}
            </ul>}

        </div>
    )
}



export default PopularTv;