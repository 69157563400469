import React , { useState, useEffect } from 'react';
import axios from 'axios';
import Movie from './Movie';
import { useHistory, Link } from 'react-router-dom';


const SimilarTv = ({tv_id}) => {
    //Backend
    const [similarTv, setSimilarTv] = useState([]);
    const history = useHistory();

    useEffect(() => {
        axios.get(`https://api.themoviedb.org/3/tv/${tv_id}/similar?api_key=6625ff3fdd9fb20f78f226927fb449e9&language=en-US&page=1`).then((res) => {console.log(res.data);setSimilarTv(res.data.results)});
    }, []);

    //Front
    return(
        <div className='popularMovies'>
            <div className="heading2">
                <div>
                    <h1 className='titleCategories'>Séries Télé que vous pourriez aimer</h1>
                </div>
                <div className="tous">
                    <h2>Voir Tous</h2>
                    <Link to="/categories"><button className="next">❯</button></Link>               
                </div>
            </div>
            {<ul className='popularMovies-list'>
                {similarTv.slice(0,5).map((m) => (
                    <Movie movie={m} key={m.id} onClickMovie={() => { history.push(`/`);setTimeout(()=>{history.push(`/TvDetails/${m.id}`)},100)}} />
                ))}
            </ul>}

        </div>
    )
}



export default SimilarTv;