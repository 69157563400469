import React from 'react';
import Card from 'react-bootstrap/Card';
import StarIcon from "@mui/icons-material/Star";
  
const Team = () => {

  return (
    
    <div 
    style={{
       backgroundSize: "contain",
       backgroundImage: `url(a-propos.jpg)`,
       backgroundPosition: "center center",
       backgroundAttachment: "fixed",
       maxWidth: "100%",
       maxHeight: "100%",
       padding: "4%",}}
       >
    <div className="container equipe col-sm-12 p-10">
        <h2>DEVFLIX,</h2>
        <h3>Click & Chill!</h3>
        <p className='team_description'>Le site web (DEVFLIX) est une platforme de films et de séries télévisées.
        Les données sont mise a jour régulierement .
        La forte orientation internationale de Devflix et l’étendue de ses données sont
        largement inégalées et c'est quelque chose dont nous sommes incroyablement fiers.<br /><br />
        Bon visionnement sur DEVFLIX !</p>
      
        <div className='team'>
            <Card className='team_card' style={{ width: '21rem' }} >
                <Card.Img className='team_img' src={"denis.png"} 
                        alt="poster" />
                <Card.Body>
                    <div className='team_expert'>
                    <span className="m-auto mb-4 movie__star">
                        <StarIcon />
                        Programmeur Analyste
                    </span>          
                    </div>
                    <Card.Title className='team_title'>
                        <h3>1. Denis Lacombe</h3>
                        <p>Passioné du domaine de l`informatique depuis son jeune age, il est plein d`idées, de ressources et adore le challenge. De plus, sa capacité de manager est aussi une aptitude qu'il domine.</p>
                    </Card.Title>
                </Card.Body>
            </Card>
            <Card  className='team_card' style={{ width: '21rem' }} >
                <Card.Img className='team_img' src={"nathalia.jpg"} 
                        alt="poster" />
                <Card.Body>
                    <div className='team_expert'>
                    <span className="m-auto mb-4 movie__star">
                        <StarIcon />
                        Intégratice Web
                    </span>          
                    </div>
                    <Card.Title className='team_title'>
                        <h3>2. Nathalia Andrade</h3>
                        <p>Aprés une brillante carrière dans le domaine légal elle se trouve une nouvelle passion dans le développement web. Dottée d'une sensibilité aux détails, Nathalia aime exploiter les techniques d'intégration.</p>
                    </Card.Title>
                </Card.Body>
            </Card>
            <Card  className='team_card' style={{ width: '21rem' }} >
                <Card.Img className='team_img' src={"majdi.jpg"} 
                        alt="poster" />
                <Card.Body>
                    <div className='team_expert'>
                    <span className="m-auto mb-4 movie__star">
                        <StarIcon />
                        Designer Web
                    </span>          
                    </div>
                    <Card.Title className='team_title'>
                        <h3>3. Majdi Hellal</h3>
                        <p>Lui aussi est passionné d'informatique depuis sa jeunesse . Il est toujours en quête d`apprentissages de nouvelles techniques dans le domaine web et n`a pas peur de relever de nouveaux défis.</p>
                    </Card.Title>
                </Card.Body>
            </Card>
        </div>
    </div>
    </div>
  );
}

export default Team;