import React from 'react';


const truncate = (string, num) => {
    return string?.length > num ? string.substr(0, num - 3) + "..." : string;
  };
  
const Movie3 = (props) => {
    const { movie, onClickMovie } = props;

  return (
    <div  onClick={onClickMovie}>
        <figure class="snip1578 hover"><img src={
                movie.poster_path ?
                `https://image.tmdb.org/t/p/original${movie.poster_path}` : 
                "../images/img_not_found_JPEG.jpg"
                } 
               alt="poster" />
        <figcaption>


        <h3 className='search_title'>{truncate(movie.title || movie.name)}</h3>
        <p className='search_date'>
            {movie.release_date}
        </p>
        <p>
            {movie.overview}
        </p>
        </figcaption>
        </figure>




    </div>
  );
  
}

export default Movie3;