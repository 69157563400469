// RÉGLER LE CAROUSEL


import React, { useEffect,useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { useHistory ,Link} from 'react-router-dom';
import axios from "axios";
import ModalVideo from 'react-modal-video'

function CarouselFadeExample() {

  const [popular, setPopular] = useState([]);
  const history = useHistory();
  const [video,setVideo] = useState('')
  const  [isOpen,setOpen] = useState(false)
  const [index, setIndex] = useState(0);
  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };
  console.log(popular[0]?.id,'popular')
  const URL_VIDEO = `https://api.themoviedb.org/3/movie/${popular[index]?.id}/videos?api_key=6625ff3fdd9fb20f78f226927fb449e9&language=en-US`;

  useEffect(() => {

      axios.get("https://api.themoviedb.org/3/movie/popular?api_key=a67b57849deb687f2cd49d7a8298b366&language=fr-FR&page=1")
      .then((res) =>{ 
        
        setPopular(res.data.results.filter((_,index)=>index >= 5 && index<10 ))
      });

      axios.get(URL_VIDEO).then((res) => {
        setVideo(res.data.results[0]);
        console.log(res.data)
    });
  }, [index]);

  return (
    <Carousel  activeIndex={index} fade onSelect={handleSelect}>
      {popular.map((movie=>(
     
      <Carousel.Item >
        <div style={{position:'relative',height:'100vh'}}>
        <img 
          style={{position:'absolute',left:0,top:0,zIndex:1,width:'100%!important'}}
          src={`https://image.tmdb.org/t/p/original/${movie.backdrop_path}`}
          alt="Third slide"
        />
       
        {/*  jouer seulement avec opacity et background*/}

        <Link to={`/movieDetails/${movie.id}`}>
        <div style={{position:"relative",zIndex:2,width:'100%',height:'100%',top:0,opacity:'0.3'}}></div>
        </Link>
        </div>
       
        <Carousel.Caption className='carousel_' style={{zIndex:5}}>
          <h3>{movie.title}</h3>
          <p>
            {/*   ******À VOIR SI DATE OU DESCRIPTION******  movie.overview*/}
            {movie.release_date}
          </p>
             {/*   ******À VOIR SI GARDER LE BOUTON OU PAS*******/} 
          <div className="button__box" src=""><button className="btn1" onClick={()=>setOpen(true)}>Regarder la bande d'annonce</button>
          </div>
          <div style={{position:'relative'}}>
           <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId={video.key} onClose={() => setOpen(false)} />
          </div>
        </Carousel.Caption>
      </Carousel.Item>
      
      )))}
    </Carousel>
  );
}

export default CarouselFadeExample;