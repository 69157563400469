// BackButton.js

import React from 'react'
import { Button } from 'react-bootstrap'
import { withRouter } from 'react-router'

const BackButton = ({ history: { goBack }, children, ...props }) => (
  <Button {...props} onClick={goBack}>
    {children}
    <h2 className='BackButton'>Retour</h2>
  </Button>
)

export default withRouter(BackButton)