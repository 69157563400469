import React, { useState, useEffect } from "react";
import axios from "axios";
import Navigation from "../components/Navigation";
import Footer from "../components/Footer";
import Team from "../components/Team";


const APropos = () => {
    return(
        <div >
            <Navigation />
            <Team />
            <Footer />
           
        </div>
    )
}

export default APropos;